import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import CircularDemo from './CircularDemo';
import LoginDemo from './LoginDemo';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
// import 'primereact/resources/themes/saga-blue/theme.css';  // Optional: Theme
// import 'primereact/resources/primereact.min.css';          // Core CSS
// import 'primeicons/primeicons.css'; 
import axios from 'axios';

const LoginPage = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const login_service = async () => {
        try {
            const response = await axios.post('https://snsdapi.devsofrinventix.com/a-login/', {

                username: username,
                password: password,
            })

            // console.log('login service resp',response.data.token);
            localStorage.setItem('__aT__', response.data.token);
            navigate('/');



        }
        catch (error) {
            console.error('Error:', error);
        }
    }




    const handleLogin = (e) => {
        e.preventDefault();
        login_service();
    };

    return (
        <div className="container-fluid border h-100 d-flex">
            {/* Left Side: Login Form */}
            <div className="row w-100">
                <div className="col-6  justify-content-center align-items-center p-2 " style={{ width: '300px', margin: 'auto' }}>
                    <h2>Login</h2>
                    <form onSubmit={handleLogin}>
                        <div className="p-field w-100">
                            <label htmlFor="username">Username</label>
                            <InputText
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Enter your username"
                                className="p-inputtext"
                            />
                        </div>

                        <div className="p-field w-100" style={{ marginTop: '1rem' }}>
                            <label htmlFor="password">Password</label>
                            <Password
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                feedback={false}
                                placeholder="Enter your password"
                            // className="p-inputtext"
                            />
                        </div>

                        <div className="p-field" style={{ marginTop: '2rem' }}>
                            <Button label="Login"
                                //  icon="pi pi-sign-in"
                                className="p-button w-100" />
                        </div>
                    </form>
                </div>

                {/* Right Side: Image Preview */}
                <div className="col-6 border bg-white  d-none d-md-flex justify-content-center align-items-center p-2">
                    <LoginDemo />
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
