// src/components/FileUpload.js
import React, { useState,useRef,useEffect } from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
// import React, {  } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';


const FileUploadComponent = ({ onUploadSuccess }) => {
  const [file, setFile] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);  // or based on your auth logic
  useEffect(() => {
      // Check if the user is authenticated (you can implement your own authentication logic)
      // const isAuthenticated = 
      if (localStorage.getItem('__aT__') !== null) {
          // fetchProfiles();
          setIsAuthenticated(!isAuthenticated);
      }
      else{
          // setIsAuthenticated(!isAuthenticated);
         window.location.href = '/login';
      }
  }, []);

  const toast = useRef(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('https://snsdapi.devsofrinventix.com/upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `${localStorage.getItem('__aT__')}`
        },
      });
      alert("File uploaded successfully!");
      onUploadSuccess(); // Refresh the list after upload
    } catch (error) {
      alert("Error uploading file");
      console.error(error);
    }
  };

  return (
    <div className="file-upload">
      {/* <input type="file" onChange={handleFileChange} /> */}
      <div className="card flex justify-content-center">
            <Toast ref={toast}></Toast>
            <FileUpload mode="basic" name="file" url="https://snsdapi.devsofrinventix.com/upload/" accept="csv/*" maxFileSize={1000000} onUpload={handleFileChange} />
        </div>  
      {/* <Button className="btn btn-primary mt-2" onClick={handleUpload}>Upload</Button> */}
      <Button onClick={handleUpload} label="Upload" icon="pi pi-file-pdf" />

    </div>
  );
};

export default FileUploadComponent;
