import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import FileUploadComponent from './componenets/Fileupload';
// import UserProfileList from './componenets/UserProfileList';
import UserProfileTable from './componenets/UserProfileTable';
import { PrimeReactProvider } from 'primereact/api';
import UserProfileAttendance from './componenets/UserProfileAttendance';
import NavBar from './componenets/Navbar';
import ProtectedRoute from './componenets/ProtectedRoute';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';

import CircularDemo from './componenets/CircularDemo';
import LoginPage from './componenets/Login';
import NewDateRangeFilter from './componenets/NewDateRangeFilter';
// import logo
// import pngwing from 'pngwing.png';
// import logo from '../src/logo/pngwing.png';

const App = () => {
  const [refresh, setRefresh] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);  // or based on your auth logic


  useEffect(() => {
    console.log('localStorage', localStorage.getItem('__aT__'));

    if (localStorage.getItem('__aT__') !== null) {

      setIsAuthenticated(!isAuthenticated);
    }

  }, []);



  const handleUploadSuccess = () => {
    setRefresh(!refresh); // Toggle refresh state to trigger list refresh
  };

  return (
    <Router>
      <div className="container-fluid align-items-center">

        <PrimeReactProvider>
          <NavBar />


          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={
              // <ProtectedRoute >

              <Home />
              // </ProtectedRoute>
            } />

            <Route path="/attendance" element={
              // <ProtectedRoute>

              <UserProfileAttendance />
              // </ProtectedRoute>
            } />
            <Route path="/profiles" element={
              // <ProtectedRoute>

              <UserProfileTable />
              // </ProtectedRoute>  
            } />
            <Route path="/upload" element={
              // <ProtectedRoute>
              <FileUploadComponent onUploadSuccess={handleUploadSuccess} />
              // </ProtectedRoute>
            } />


          </Routes>
        </PrimeReactProvider>
      </div>
    </Router>
  );
};

// Simple Home component for the home page
const Home = () => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);  // or based on your auth logic
  useEffect(() => {
    // Check if the user is authenticated (you can implement your own authentication logic)
    // const isAuthenticated = 
    if (localStorage.getItem('__aT__') !== null) {
      // fetchProfiles();
      setIsAuthenticated(!isAuthenticated);
    }
    else {
      // setIsAuthenticated(!isAuthenticated);
      window.location.href = '/login';
    }
  }, []);

  return (
    <div className='text-center'>
      <h2 className='mt-5'>Welcome to the Sewadal Attendance App</h2>
      <p>Please use the navigation above to explore the app.</p>
      <CircularDemo />
    </div>
  );
};

export default App;
