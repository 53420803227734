
import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { Tag } from 'primereact/tag';
// import { ProductService } from './service/ProductService';
import image1 from '../7.jpg'
import image2 from '../3.jpg'
import image4 from '../4.jpg'
import image3 from '../3_devotion.jpg'

export default function CircularDemo() {
    let product = [
        {
            id: 1,
            image: image1,
            name: "Mataji"
        },
        {
            id: 2,
            image: image2,
            name: "Mataji"
        },
        {
            id: 3,
            image: image3,
            name: "Mataji"
        },
        {
            id: 4,
            image: image4,
            name: "Mataji"
        },

    ]

    const [products, setProducts] = useState(product);
    // const responsiveOptions = [
    //     {
    //         breakpoint: '1400px',
    //         numVisible: 2,
    //         numScroll: 1
    //     },
    //     {
    //         breakpoint: '1199px',
    //         numVisible: 3,
    //         numScroll: 1
    //     },
    //     {
    //         breakpoint: '767px',
    //         numVisible: 2,
    //         numScroll: 1
    //     },
    //     {
    //         breakpoint: '575px',
    //         numVisible: 1,
    //         numScroll: 1
    //     }
    // ];

    const getSeverity = (product) => {
        switch (product.inventoryStatus) {
            case 'INSTOCK':
                return 'success';

            case 'LOWSTOCK':
                return 'warning';

            case 'OUTOFSTOCK':
                return 'danger';

            default:
                return null;
        }
    };



    // useEffect(() => {
    //     ProductService.getProductsSmall().then((data) => setProducts(data.slice(0, 9)));
    // }, []);

    const productTemplate = (product) => {
        return (
            <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
                <div className="mb-3">
                    <img src={product.image} alt={product.name} className="w-100 h-50 shadow-2" />
                </div>
                <div>
                    <h4 className="mb-1">{product.name}</h4>
                </div>
            </div>
            // </div >
        );
};

return (
    <div className="card">
        <Carousel value={products} numVisible={1} numScroll={1}
        //  responsiveOptions={responsiveOptions}
          className="custom-carousel" circular
            autoplayInterval={3000} itemTemplate={productTemplate} />
    </div>
)
}

// Monthwise attendance percentage included
// adhikari-sewadal groups
// profile photos