import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import NewDateRangeFilter from './NewDateRangeFilter';

const GetAttendance = () => {
    const [date, setDate] = useState(null);  // State to store the selected date
    const [data, setData] = useState([]);    // State to store the data fetched from the API

    // Fetch data based on the selected date
    const fetchData = async () => {
        if (date) {
            try {
                // Format the date to match the API requirement (e.g., YYYY-MM-DD)
                const formattedDate = date.toISOString().split('T')[0];

                // Send a POST request with the selected date as the payload
                const response = await axios.post('https://snsdapi.devsofrinventix.com/attendance/get-datewise-attendance/', { date: formattedDate },
                    {
                        headers: {
                            'Accept': '*/*',
                            'Content-Type': 'application/json',
                            'Authorization': `${localStorage.getItem('__aT__')}`
                        }
                    }
                );

                // Set the data received from the API to the state
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    const nameTemplate = (rowData) => {
        return rowData.user_profile?.name;
    };

    const genderTemplate = (rowData) => {
        return rowData.user_profile?.gender;
    };

    const personalNumberTemplate = (rowData) => {
        return rowData.user_profile?.new_personal_number;
    };

    // Custom template for 'present_vardi'
    const presentVardiTemplate = (rowData) => {
        return (
            <span style={{
                backgroundColor: rowData.present_vardi ? 'yellow' : 'red',
                color: 'black',
                padding: '0.7em',
                borderRadius: '12px'
            }}>
                {rowData.present_vardi ? 'Present Vardi' : 'Civil'}
            </span>
        );
    };


    const generatePDF = () => {
        const doc = new jsPDF();
        console.log(data);

        // Add a title
        doc.text(`Sewadal Attendance Report`, 14, 20);
        doc.setFontSize(10);  // You can adjust the size as needed
        doc.text(`Total Present: ${Object.keys(data).length}`, 15, 25);

        // doc.text(`Total Present ${Object.keys(data).length}`, 15, 25);

        // Format JSON data for the table
        const tableColumn = ["Name", "Vardi Present", "Civil Present", "Date"];
        const tableRows = [];

        data.forEach(item => {
            const userData = [
                item.user_profile.name,    // Name from JSON
                item.present_vardi ? 'Yes' : 'No', // Vardi present status
                item.present ? 'Yes' : 'No',  // Civil present status
                item.date,    // Attendance Date
            ];
            tableRows.push(userData);
        });

        // Add the table to PDF
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 30,
        });

        // Save the PDF
        doc.save('attendance_report.pdf');

    }



    useEffect(() => {
        fetchData();
    }, [date]);



    return (
        <>
            <div className="card">
                {/* <div className=""> */}
                <div className="p-3">
                    <Calendar
                        value={date}
                        // onChange={(e) => setDate(e.value)}
                        onChange={(e) => setDate(new Date(e.value.getTime() - e.value.getTimezoneOffset() * 60000))}

                        placeholder="Select Date"
                        dateFormat="yy-mm-dd"
                    />
                    <Button
                        label="Fetch Data"
                        icon="pi pi-search"
                        onClick={fetchData}
                        disabled={!date}
                    />
                </div>
                {/* </div> */}
                <div className='p-3'>

                    <h4>
                        Total Attendance: {Object.keys(data).length}

                    </h4>
                    <Button
                        label="Download Data"
                        icon="pi pi-download"

                        onClick={generatePDF}
                    disabled={!date}
                    />
                    <br />
                    <DataTable value={data} paginator rows={50}>
                        <Column field="user_profile.name" header="Name" body={nameTemplate} />
                        {/* <Column field="user_profile.new_personal_number" header="New Personal Number" body={personalNumberTemplate} /> */}

                        <Column field="present_vardi" header="Present Vardi" body={presentVardiTemplate} />
                        <Column field="user_profile.gender" header="Gender" body={genderTemplate} />
                        {/* <Column field="present" header="Present Civil" body={presentCivilTemplate} /> */}
                        <Column field="date" header="Date" />
                    </DataTable>
                </div>
            </div>
            <div className='mt-5 card p-3'>
                <NewDateRangeFilter/>
            </div>
        </>
    );
};

export default GetAttendance;
