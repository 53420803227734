// src/components/NavBar.js
import React from 'react';
import { Menubar } from 'primereact/menubar';
import logo from "../pngwing.png";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { useNavigate } from 'react-router-dom';

// import { Button } from 'primereact/button';

const NavBar = () => {
    // const router = useHistory();
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('__aT__');
        window.location.href = '/login';
    }


    const items = [
        {
            label: 'Home',
            icon: 'pi pi-home',
            command: () => {
                navigate('/');
            }
        },
        {
            label: 'Profiles',
            icon: 'pi pi-fw pi-users',
            command: () => { navigate("/profiles") }
        },
        {
            label: 'Upload',
            icon: 'pi pi-fw pi-upload',
            command: () => { navigate("/upload") }
        },
        {
            label: 'Attendance',
            icon: 'pi pi-fw pi-calendar',
            command: () => { navigate("/attendance") }
        }
    ];


    const start = (
        <div>
            <img alt="logo" src={logo} height="40" className="mx-2"></img>
        </div>
    );


    const navstart = (
        <div className='d-flex align-items-center gap-5'>
            <span>
                <img alt="logo" src={logo} height="40" className="mx-2"></img>
            </span>
            <span>
                {/* <h4 style={fontfamily="Titillium Web"}>Sewadal Attendance Application Unit No 1214</h4> */}
                <h5 style={{ fontFamily: 'Titillium Web, sans-serif ',fontWeight:200,fontSize:'120%',fontStyle: 'italic' }}>Sewadal Attendance Application Unit No 1214</h5>

            </span>
        </div>
    );


    const end = (
        <div className="d-flex align-items-center gap-5">
            {/* <InputText placeholder="Search" type="text" className="w-8rem sm:w-auto" /> */}
            {localStorage.getItem('__aT__') && <Button
                label="Logout" icon="pi pi-power-off" className="p-button-secondary" onClick={handleLogout} />}

        </div>
    );

    return (
        // <div >
        localStorage.getItem('__aT__') && (
            <Menubar
                className='pt-3'
                model={items}
                start={start}
                end={end}

            >

            </Menubar>
        ) || (
            <Menubar
                className='pt-3'
                // model={items}
                start={navstart}
                end={end}

            >
            </Menubar>

        )

        // </div>
    );
}

export default NavBar;
