import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
const ProtectedRoute = ({  children }) => {
    console.log('PR localStorage', localStorage.getItem('__aT__'));

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    useEffect(() => {
        // Check if the user is authenticated (you can implement your own authentication logic)
        const isAuthenticated = localStorage.getItem('__aT__') !== null;
        setIsAuthenticated(!isAuthenticated);
    }, []);
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
