import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
// import FilteredDataTable from 'FilteredDataTable';
import GetAttendance from './GetAttendance'
import DateRangeAttendanceTable from './DateRangeAttendanceTable';
import NavBar from './Navbar';

export default function UserProfileAttendance({ submitAttendanceUrl }) {
    // const [profiles, setProfiles] = useState([]);
    
    const [data, setData] = useState([]);
    const [markAttendance, setMarkAttendance] = useState(false);
    
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [attendance, setAttendance] = useState({});
    
    const attendanceOptions = [
        { label: 'Present-Vardi', value: 'present-vardi' },
        { label: 'Present-Civil', value: 'present-civil' }
    ];
    
    const [isAuthenticated, setIsAuthenticated] = useState(false);  // or based on your auth logic
    useEffect(() => {
        // Check if the user is authenticated (you can implement your own authentication logic)
        // const isAuthenticated = 
        if (localStorage.getItem('__aT__') !== null) {
            // fetchProfiles();
            setIsAuthenticated(!isAuthenticated);
        }
        else{
            // setIsAuthenticated(!isAuthenticated);
           window.location.href = '/login';
        }
    }, []);
    // return isAuthenticated ? children : <Navigate to="/login" />;
    
    
    const fetchProfiles = async () => {
        try {
          const response = await axios.get('https://snsdapi.devsofrinventix.com/userprofiles/',{
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Authorization': `${localStorage.getItem('__aT__')}`
            }
          });
          setData(response.data.results);
        //   setProfiles(response.data.results);
        } catch (error) {
          console.error("Error fetching profiles:", error);
        }
      };

    useEffect(()=>{
        fetchProfiles();
    },[])


    // useEffect(() => {
    //     setData(profiles);
    // }, [profiles]);

    const onAttendanceChange = (value, rowData) => {
        setAttendance({
            ...attendance,
            [rowData.id]: { status: value, name: rowData.name }
        });
    };

    const onSubmitAttendance = async () => {
        const attendanceData = Object.entries(attendance).map(([id, details]) => ({
            user_profile: id,
            name: details.name,
            date: selectedDate,
            status: details.status
        }));

        try {
            await axios.post("https://snsdapi.devsofrinventix.com/attendance/mark-attendance/", attendanceData,
                {
                    headers: {
                        'Accept': '*/*',
                        'Content-Type': 'application/json',
                        'Authorization': `${localStorage.getItem('__aT__')}`
                    }
                }
            );
            alert('Attendance submitted successfully!');
        } catch (error) {
            console.error('Error submitting attendance:', error);
            alert('Failed to submit attendance.');
        }
    };

    const attendanceBodyTemplate = (rowData) => {
        return (
            <Dropdown
                value={attendance[rowData.id]?.status}
                options={attendanceOptions}
                onChange={(e) => onAttendanceChange(e.value, rowData)}
                placeholder="Select Attendance"
            />
        );
    };

    const header = (
        <div className="flex justify-content-between align-items-center">
            <span>

                <InputText
                    type="search"
                    onInput={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search by name"
                />
            </span>

            <span className=''>

                <Calendar
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(new Date(e.value.getTime() - e.value.getTimezoneOffset() * 60000))}
                    placeholder="Select a date"
                    dateFormat="yy-mm-dd"
                />
            </span>
            <span>

                <Button
                    label="Submit Attendance"
                    icon="pi pi-check"
                    className=''
                    onClick={onSubmitAttendance}
                    disabled={!selectedDate || Object.keys(attendance).length === 0}
                />
            </span>
        </div>
    );

    return (
        <div className="">
            {/* <NavBar /> */}
            <div className='p-2'>

            <Button
                    label={markAttendance?"Get Datewise Attendance":"Mark Attendance"}
                    // icon="pi pi-check"
                    className=''
                    onClick={() => setMarkAttendance(!markAttendance)} 
                    // disabled={!selectedDate || Object.keys(attendance).length === 0}
                />
            </div>
            <div className='pt-4'>

            {markAttendance===true &&(

            <DataTable
                value={data}
                paginator
                rows={5}
                globalFilter={globalFilter}
                header={header}
                emptyMessage="No profiles found.">
                    
                <Column field="name" header="Name" filter />
                <Column header="Attendance" body={attendanceBodyTemplate} />
            </DataTable>
            )||(
                <>
                
                <GetAttendance/>
                </>
            )}
            </div>
            

        </div>
    );
}
