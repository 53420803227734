import React, { useState ,useEffect} from 'react';
import { DataTable } from 'primereact/datatable';  // Corrected import
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';

// import { CustomerService } from './service/CustomerService'; // Adjust this import to match your actual service

export default function UserProfileTable({  }) {
  const [profiles, setProfiles] = useState([]);

  console.log(profiles);
  // const [data, setData] = useState(profiles);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    name: { value: null, matchMode: 'startsWith' },
    gender: { value: null, matchMode: 'equals' }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const [isAuthenticated, setIsAuthenticated] = useState(false);  // or based on your auth logic
  useEffect(() => {
      // Check if the user is authenticated (you can implement your own authentication logic)
      // const isAuthenticated = 
      if (localStorage.getItem('__aT__') !== null) {
          // fetchProfiles();
          setIsAuthenticated(!isAuthenticated);
      }
      else{
          // setIsAuthenticated(!isAuthenticated);
         window.location.href = '/login';
      }
  }, []);


  const handleGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters({ ...filters, global: { value, matchMode: 'contains' } });
    setGlobalFilterValue(value);
  };


  const fetchProfiles = async () => {
    try {
      const response = await axios.get('https://snsdapi.devsofrinventix.com/userprofiles/',
        {
          headers: {
            'Accept': '*/*',
            'Content-Type': 'application/json',
            'Authorization': `${localStorage.getItem('__aT__')}`
          },
        }
      );
      setProfiles(response.data.results);
    } catch (error) {
      console.error("Error fetching profiles:", error);
    }
  };

  useEffect(() => {
    fetchProfiles();
  }, []);

  const downloadPDF = () => {
    const doc = new jsPDF();
    console.log('filters',filters.global.value);

    let adhikari_filter = profiles.filter(item => {
      return item.adhikari?.name.toLowerCase().includes(filters.global.value.toLowerCase());})
    console.log(adhikari_filter);
    
    doc.text('User Profiles', 14, 20);
    doc.setFontSize(10);
    doc.text(`Total count: ${Object.keys(adhikari_filter).length}`, 15, 25);
    doc.autoTable({
      head: [['Name', 'Gender', 'New P#', 'Old P#', 'DOB', 'Qualification', 'Address', 'Occupation', 'Blood Group', 'Contact No']],
      body: adhikari_filter.map(item => [item.name, item.gender, item.new_personal_number, item.old_personal_number, item.DOB, item.qualification, item.address, item.occupation, item.blood_group, item.contact_no]),
      startY: 30,
    });
    doc.save('user_profiles.pdf');
  };

  const genderItemTemplate = (option) => <Tag value={option} severity="info" />;

  return (
    <div className="card">
      <div className="flex justify-content-between mb-3">
        <InputText
          value={globalFilterValue}
          onChange={handleGlobalFilterChange}
          placeholder="Global Search"
          className="mr-2"
        />
        <Button onClick={downloadPDF} label="Download as PDF" icon="pi pi-file-pdf" />
      </div>
      <DataTable
        value={profiles}
        paginator
        rows={50}
        filters={filters}
        filterDisplay="row"
        globalFilterFields={['gender','adhikari.name']}
        emptyMessage="No user profiles found."
      >
        <Column
          field="name"
          header="Name"
          filter
          filterPlaceholder="Search name"
        />
        <Column
          field="gender"
          header="Gender"
          filter
          filterPlaceholder="Search gender"
          body={(rowData) => <Tag value={rowData.gender} severity="info" 
          />}
        />
        <Column field="new_personal_number" header="New Personal#" body={(rowData) => <Tag value={rowData.new_personal_number} severity="warning" 
          />}/>
        {/* <Column field="old_personal_number" header="Old P#" /> */}
        {/* <Column field="DOB" header="DOB" /> */}
        <Column field="contact_no" header="Contact No" />
        <Column field="adhikari.name" header="Adhikari" />
        {/* <Column field="occupation" header="Occupation" /> */}
        <Column field="blood_group" header="Blood Group" />
        <Column field="address" header="Address" />
      </DataTable>
    </div>
  );
}
