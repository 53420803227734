import React, { useState, useEffect } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';

const NewDateRangeFilter = () => {

    const [dates, setDates] = useState(null);
    const [filteredData, setFilteredData] = useState([]);

    const [data, setData] = useState([]);    // State to store the data fetched from the API

    const handleSubmit = async () => {
        console.log('dates', dates);
        fetchData();
    }


    const handleDateChange = (e) => {
        const selectedDates = e.value;
        if (selectedDates && selectedDates[0] && selectedDates[1]) {
            // Convert the selected dates from GMT to IST
            const startDateIST = new Date(selectedDates[0].getTime() + 5.5 * 60 * 60 * 1000); // IST is GMT+5:30
            const endDateIST = new Date(selectedDates[1].getTime() + 5.5 * 60 * 60 * 1000);

            setDates([startDateIST, endDateIST]);  // Set the converted dates to state
        } else {
            setDates(selectedDates);
        }
    };

    const formatDate = (date) => {
        const dateObj = new Date(date);
        return dateObj.toISOString().split('T')[0];  // Extract only the date part (YYYY-MM-DD)
    };

    // Fetch data based on the selected date
    const fetchData = async () => {
        console.log('dates', dates);

        if (dates) {
            try {
                // Format the date to match the API requirement (e.g., YYYY-MM-DD)
                // const formattedDate = dates.toISOString().split('T')[0];
                if (dates && dates[0] && dates[1]) {
                    const start_date = formatDate(dates[0])
                    const end_date = formatDate(dates[1])


                    // Send a POST request with the selected date as the payload
                    const response = await axios.post('https://snsdapi.devsofrinventix.com/attendance/get-daterange-attendance/', { start_date, end_date },
                        {
                            headers: {
                                'Accept': '*/*',
                                'Content-Type': 'application/json',
                                'Authorization': `${localStorage.getItem('__aT__')}`
                            }
                        }
                    );

                    // Set the data received from the API to the state
                    setData(response.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };



    return (
        <div>
            <h2>Get Attendance by Date Range</h2>

            {/* Date Range Picker */}
            <div className="p-field mb-4">
                {/* <label className='' htmlFor="daterange">Select Date Range</label> */}
                <Calendar
                    id="daterange"
                    value={dates}
                    onChange={handleDateChange}
                    selectionMode="range"
                    readOnlyInput
                    placeholder="Select a date range"
                    dateFormat="yy-mm-dd"
                // className="p-inputtext"
                />
                <Button label="Fetch Data" 
                disabled={!dates}

                icon="pi pi-search" onClick={handleSubmit} className="p-button" style={{ marginTop: '1rem' }} />
            </div>


            {/* Display Filtered Data */}
            <div className='w-50'>

                <Accordion>
                    {Object.entries(data).map(([date, records]) => (

                        <AccordionTab key={date} header={date}>
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead >
                                    <tr>
                                        <th>Name</th>
                                        <th>Gender</th>
                                        <th>Present Vardi</th>
                                        {/* <th>New Personal Number</th> */}
                                    </tr>
                                </thead>
                                <tbody className='pt-2'>
                                    {records.map(record => (
                                        <tr key={record.id}>
                                            <td>
                                                <p className='font-weight-bold'>

                                                    {record.user_profile.name.trim()}
                                                </p>
                                            </td>
                                            <td>
                                                <p className={record.user_profile.gender === "Male" ? 'text-white bg-primary btn' : 'text-white bg-info btn'}>

                                                    {record.user_profile.gender}
                                                </p>

                                            </td>
                                            <td >
                                                <p className={record.present_vardi ? 'text-white bg-danger btn' : 'text-white bg-info btn'}>

                                                    {record.present_vardi ? 'Present Vardi' : 'Civil'}
                                                </p>
                                            </td>
                                            {/* <td>{record.user_profile.new_personal_number}</td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </AccordionTab>
                    ))}
                </Accordion>
            </div>
        </div>
    );
};

export default NewDateRangeFilter;
