import React, { useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import "react-datepicker/dist/react-datepicker.css";
// import Calendar from './Calendar';
// import Calendar from './Calendar'
import DateRangePicker from 'bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';


const DateRangeAttendanceTable = () => {
    const [startDate, setStartDate] = useState(moment().startOf('month'));
    const [endDate, setEndDate] = useState(moment().endOf('month'));

    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    const [data, setData] = useState({});

    const fetchAttendanceData = async () => {
        try {
            if (startDate && endDate) {
                const response = await axios.post('https://snsdapi.devsofrinventix.com/attendance/get-daterange-attendance/', {
                    start_date: startDate.toISOString().split('T')[0],
                    end_date: endDate.toISOString().split('T')[0]
                }, {
                    headers: {
                        'Accept': '*/*',
                        'Content-Type': 'application/json',
                        'Authorization': `${localStorage.getItem('__aT__')}`
                    }
                });
                setData(response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleDateRangeChange = (start, end) => {
        console.log('Selected Start Date:', start.format('YYYY-MM-DD'));
        console.log('Selected End Date:', end.format('YYYY-MM-DD'));
      };


    const Calendar = ({ onDateRangeChange }) => {

      
        const handleEvent = (event, picker) => {
          setStartDate(picker.startDate);
          setEndDate(picker.endDate);
          if (onDateRangeChange) {
            onDateRangeChange(picker.startDate, picker.endDate);
          }
        };
      
        return (
          <div>
            <DateRangePicker
              initialSettings={{
                startDate: startDate.toDate(),
                endDate: endDate.toDate(),
                locale: {
                  format: 'YYYY-MM-DD',
                },
              }}
              onApply={handleEvent}
            >
              <input type="text" className="form-control" />
            </DateRangePicker>
          </div>
        );
      };

    return (
        <div>
            <h2>Attendance Data</h2>
            <Calendar onDateRangeChange={handleDateRangeChange}/>
            <button onClick={fetchAttendanceData}>Fetch Data</button>
            {/* Render your data here */}
            <DataTable value={Object.keys(data)} header="Attendance Data">
                {Object.keys(data).map(date => (
                    <Column key={date} field={date} header={date} body={() => (
                        <ul>
                            {data[date].map(record => (
                                <li key={record.id}>
                                    {record.user_profile.name} - {record.present ? 'Present' : 'Absent'}
                                </li>
                            ))}
                        </ul>
                    )} />
                ))}
            </DataTable>
        </div>
    );
};

export default DateRangeAttendanceTable;
